import { defineStore } from 'pinia';
import { isPlatform } from '@ionic/vue';
import useApi from "@/composables/useApi";
import qs from 'qs';
import {useUserStore} from "@/stores/user";
import {Browser} from "@capacitor/browser";
import { AppLauncher } from '@capacitor/app-launcher';
import {Capacitor} from "@capacitor/core";

export const useBankIdStore = defineStore('bankIdStore', {
  state: () => ({
    qrCode: null as string | null,
    bankIdMessage: null as string | null,
    orderRef: null as string | null,
    bankIdFailed: false,
    collecting: false,
    loggingIn: false,
    collectCount: 0,
  }),
  actions: {
    async startBankIdAuth() {
      const { apiGet } = useApi();
      const isDesktop = !isPlatform('ios') && !isPlatform('android');
      console.log(isDesktop);
      let params;
      this.bankIdFailed = false;
      this.collecting = true;

      if (isDesktop) {
        params = {
          bankIdMessage: 'VmV0ZXJhbnBvb2xlbiBpbmxvZ2duaW5n',
        };
      }

      const query = qs.stringify(params)

      const loginRes = await apiGet<{ result: { autoStartToken: string; orderRef: string } }>(`/auth/login?${query}`);

      if (loginRes && loginRes.result) {
        this.orderRef = loginRes.result.orderRef;
        if (isDesktop) {
          await this.collectBankId(isDesktop);
        } else {
          try {
            const url = isPlatform('ios') && Capacitor.isNativePlatform()
              ? `https://app.bankid.com/?autostarttoken=${ loginRes.result.autoStartToken }&redirect=vappen://bankid-callback`
              : `bankid:///?autostarttoken=${ loginRes.result.autoStartToken }&redirect=${ Capacitor.isNativePlatform ? 'vappen://bankid-callback' : 'null'}`;

            const { value } = await AppLauncher.canOpenUrl({ url });
            if (isPlatform('ios') && !Capacitor.isNativePlatform()) {
              const a = document.createElement('a');
              a.href = url;
              a.click();
            } else {
              await AppLauncher.openUrl({ url });
            }
            await this.collectBankId(isDesktop);
          } catch (e) {
            this.bankIdMessage = 'Kunde inte starta BankID-appen, vänligen bekräfta att du har BankID-appen installerad och försök igen.';
          }
        }
      }
    },
    async collectBankId(isDesktop = false) {
      const { apiGet } = useApi();
      const { login } = useUserStore();
      this.loggingIn = false;
      this.collectCount += 1;
      const res = await apiGet<{ result: {
        qrData: string;
        message: string;
        status: string;
        access_token: string;
        access_token_expires_at: number;
      } }>(`/auth/collect-qr?orderRef=${this.orderRef}`, { version: 2 });
      if (res) {
        if (isDesktop) {
          this.qrCode = res.result.qrData;
        }
        this.bankIdMessage = res.result.message;

        if (res.result.status === 'failed' || this.collectCount > 60) {
          this.bankIdFailed = true;
          this.collecting = false;
        } else if (res.result.status === 'pending') {
          setTimeout(() => this.collectBankId(isDesktop), 1000);
        } else {
          this.collecting = false;
          this.loggingIn = true;
          await login({
            accessToken: res.result.access_token,
            accessTokenExpiresAt: res.result.access_token_expires_at,
          })
          this.loggingIn = false;
        }
      } else {
        this.bankIdFailed = true;
        this.collecting = false;
        this.bankIdMessage = 'Kunde inte verifiera BankID, vänligen försök igen.';
      }
    }
  }
});
